<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-if="$store.state.villageId>0" v-permission="['cliqueadd']">
					<el-button @click="bindfrom('新增')" color="#00ad79" style="color: white" class="el-white"
						size="mini">新增
					</el-button>
				</li>
				<li v-if="$store.state.villageId>0" v-permission="['cliquedele']">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.keyword" placeholder="请输入姓名">
					</el-input>
				</li>
				<li v-if="$store.state.villageId==0">
					<el-select clearable filterable v-model="form.village_id" placeholder="请选择村">
						<el-option v-for="item in cunlist" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</li>
				<li>
					<el-select clearable v-model="form.state" placeholder="请选择状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="申请中" :value="0"></el-option>
						<el-option label="资料审核通过" :value="1"></el-option>
						<el-option label="审核失败" :value="2"></el-option>
					</el-select>
				</li>
				<li>
					<el-select clearable v-model="form.identity" placeholder="请选择身份">
						<el-option label="全部" value=""></el-option>
						<el-option label="积极分子" :value="1"></el-option>
						<el-option label="发展对象" :value="4"></el-option>
						<el-option label="预备党员" :value="2"></el-option>
						<el-option label="正式党员" :value="3"></el-option>

					</el-select>
				</li>
				<li>
					<el-date-picker clearable @change="change" v-model="dates" type="datetimerange" range-separator="-"
						start-placeholder="请选择开始时间" end-placeholder="请选择结束时间">
					</el-date-picker>
				</li>
				<li>
					<el-button @click="search" color="#00ad79" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" width="80" />
				<el-table-column property="date" label="头像">
					<template #default="scope">
						<el-avatar :size="30" :src="scope.row.thumb||scope.row.avatar">
							<img src="../../assets/img/tx.png" />
						</el-avatar>
					</template>
				</el-table-column>
				<el-table-column property="village_name" label="村" />
				<el-table-column property="name" label="姓名" />
				<el-table-column property="identitytxt" label="身份" />
				<el-table-column property="sex" label="性别" />
				<el-table-column property="party_fee" label="应缴党费" />
				<el-table-column property="integral" label="总积分" />
				<el-table-column property="activity_num" label="参加活动次数" width="100" />
				<el-table-column property="tel" label="手机号码" />
				<el-table-column property="idcard" label="身份证" />
				<el-table-column property="state_txt" label="状态">
				</el-table-column>
				<el-table-column property="industry" width="100" label="所从事行业">
				</el-table-column>
				<el-table-column property="temporary_address" label="暂住地址">
				</el-table-column>
				<el-table-column property="cause" label="拒绝原因" />
				<el-table-column property="create_time" label="创建时间" />
				<el-table-column fixed="right" property="address" label="操作" width="380">
					<template #default="scope">
						<el-button v-permission="['cliquedf']"
							@click="price(scope.row.id)" size="mini">应缴党费
						</el-button>
						<el-button v-permission="['cliquedata']"
							@click="$router.push({path:'/cliqueDatas',query:{id:scope.row.id}})" size="mini">个人档案
						</el-button>
						<el-button v-if="scope.row.state==0&&$store.state.villageId>0" v-permission="['cliquepass']"
							@click="agree(scope.row.id)" size="mini">资料审核通过</el-button>
						<el-button v-if="scope.row.state==0&&$store.state.villageId>0" v-permission="['cliquerefuse']"
							@click="refuse(scope.row.id,scope.row.cause)" size="mini">拒绝</el-button>
						<el-button v-if="scope.row.identity!=3&&$store.state.villageId>0" v-permission="['cliquedits']"
							@click="bindfrom('编辑',scope.row.id)" size="mini">编辑
						</el-button>
						<el-button v-if="$store.state.villageId>0" v-permission="['cliquedele']" size="mini"
							@click="dele(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 新增/编辑弹窗 -->
		<forms ref="form" @SubmitForm="getList"></forms>
		<!-- 原因 -->
		<refuse ref="refuse" @SubmitForm="getList"></refuse>
		<!-- 党费 -->
		<price ref="price" @SubmitForm="getList"></price>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/clique";
	import forms from './components/form.vue'
	import refuse from './components/refuse.vue'
	import price from './components/price.vue'
	export default {
		components: {
			forms,
			refuse,
			price
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					keyword: '',
					page: 1,
					limit: 15,
					state: "",
					identity: '',
					village_id: '',
					activity_end_time: '',
					activity_start_time: ''
				},
				tableData: [],
				idlist: [],
				cunlist: [],
				dates: []
			}
		},
		created() {
			if (this.$route.query.state) {
				this.form.state = Number(this.$route.query.state)
				this.getList()
			} else {
				this.getList()
			}
			this.getcun()
		},
		methods: {
			// 党费
			price(id) {
				this.$refs.price.show(id)
			},
			// 选择时间
			change(e) {
				if (e) {
					this.form.activity_start_time = this.$Moment(e[0]).format('YYYY/MM/DD HH:mm:ss')
					this.form.activity_end_time = this.$Moment(e[1]).format('YYYY/MM/DD HH:mm:ss')
				} else {
					this.form.activity_start_time = ""
					this.form.activity_end_time = ""
				}
			},
			// 获取列表
			getcun() {
				this.$http.post(api.cunlist, {
						page: 1,
						limit: 999,
					})
					.then((res) => {
						if (res.code == 200) {
							this.cunlist = res.data.data
						}
					})
			},
			// 原因
			refuse(id, cause) {
				this.$refs.refuse.show(id, cause)
			},
			// 新增
			bindfrom(val, id) {
				this.$refs.form.show(val, id)
			},
			// 同意
			agree(id) {
				var that = this
				ElMessageBox.confirm(
						'是否确定通过',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.agree, {
								id: id
							})
							.then((res) => {
								if (res.code == 200) {
									that.form.page = 1
									that.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.form.state = ""
				this.form.identity = ""
				this.dates = []
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 获取列表
			getList() {
				this.form.activity_start_time = this.form.activity_start_time ? Math.round(new Date(this.form
						.activity_start_time) /
					1000) : ''
				this.form.activity_end_time = this.form.activity_end_time ? Math.round(new Date(this.form
					.activity_end_time) / 1000) : ''

				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.data
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								id: id
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped>
</style>
